/**
 * @ngdoc service
 * @module flowingly.modeler.workflow
 * @name workflowValidatorService
 *
 * @description A service that provides utility methods for working with a workflowModel
 */

import IModelNode from '@Shared.Angular/@types/modelNode';
import { Services } from '@Shared.Angular/@types/services';
import { IModelNodeLink } from '@Shared.Angular/@types/workflow';
import angular from 'angular';
const SAFE_RECURSION_MAX = 30;

angular
  .module('flowingly.services')
  .factory('flowinglyModelUtilityService', flowinglyModelUtilityService);

flowinglyModelUtilityService.$inject = [
  'lodashService',
  'devLoggingService',
  'flowinglyGatewayService',
  'flowinglyConstants',
  'flowinglyActivityService'
];

function flowinglyModelUtilityService(
  lodashService,
  devLoggingService,
  flowinglyGatewayService,
  flowinglyConstants: Services.FlowinglyConstants,
  flowinglyActivityService
) {
  //API
  const service = {
    addFieldsToNodes: addFieldsToNodes,
    getLastNodes: getLastNodes,
    getFirstModelNode: getFirstModelNode,
    getRootModelNode: getRootModelNode,
    getStartModelNode: getStartModelNode,
    findNodesAtEndOfLinks: findNodesAtEndOfLinks,
    findNodesAtStartOfLinks: findNodesAtStartOfLinks,
    getActorType: getActorType,
    getDefaultGatewayCondition: getDefaultGatewayCondition,
    getLinkThatConnectsNodes: getLinkThatConnectsNodes,
    getNodeLinkedTo: getNodeLinkedTo,
    getNodeLinkedFrom: getNodeLinkedFrom,
    getNodeByKey: getNodeByKey,
    getNodeById: getNodeById,
    getNodeKeyById: getNodeKeyById,
    getNodeLinksFrom: getNodeLinksFrom,
    getActivityNodes: getActivityNodes,
    getComponentNodes: getComponentNodes,
    getNextNodesForNode: getNextNodesForNode,
    getNextActivityNodesForNodeRecursive: getNextActivityNodesForNodeRecursive,
    getPreviousNodesForNode: getPreviousNodesForNode,
    getPreviousAcitivityNodesForNodeRecursive:
      getPreviousAcitivityNodesForNodeRecursive,
    getPreviousNodesDataSourceForDropdown:
      getPreviousNodesDataSourceForDropdown,
    getLinksFromNode: getLinksFromNode,
    getLinksToNode: getLinksToNode,
    getTrigger: getTrigger,
    getLinkProcess: getLinkProcess,
    isNodeGateway: isNodeGateway,
    isMultipleApprovalNode: isMultipleApprovalNode,
    doesNodeHaveBacklink: doesNodeHaveBacklink,
    getNodeBacklinkToNode: getNodeBacklinkToNode,
    getNextComponentNodesForNodeRecursive:
      getNextComponentNodesForNodeRecursive,
    findPreviousUntil: findPreviousUntil,
    getAllActivityNodesAfter: getAllActivityNodesAfter,
    getEligibleCellsInTable: getEligibleCellsInTable,
    getEligibleCellsInFields: getEligibleCellsInFields,
    transformTableCellToField: transformTableCellToField
  };

  return service;

  /// PUBLIC API METHODS /////////////////////////////////////////

  // This function just deals with ensuring that the model has fields that have been added
  function addFieldsToNodes(nodeDataArray, linkDataArray) {
    lodashService.forEach(nodeDataArray, function (nodeModel) {
      if (nodeModel.displayNotificationIcon === undefined) {
        nodeModel.displayNotificationIcon = false;
      }
      if (nodeModel.displayPublicFormIcon === undefined) {
        nodeModel.displayPublicFormIcon = false;
      }
      if (nodeModel.displayStepIntegrationIcon === undefined) {
        nodeModel.displayStepIntegrationIcon = false;
      }
      if (nodeModel.displayStepTaskIcon === undefined) {
        nodeModel.displayStepTaskIcon = false;
      }

      if (nodeModel.category == 'activity') {
        nodeModel.displayStepRuleIcon = false;

        lodashService.forEach(nodeModel.rules, (r) => {
          if (typeof r.isEnableRule !== 'undefined' && r.isEnableRule)
            nodeModel.displayStepRuleIcon = true;
        });
      }
    });

    const lastNodes = getLastNodes(nodeDataArray, linkDataArray);
    lodashService.forEach(lastNodes, function (nodeModel) {
      if (
        nodeModel &&
        (nodeModel.displayNotificationIcon === undefined ||
          nodeModel.displayNotificationIcon === true)
      ) {
        nodeModel.displayNotificationIcon = false;
      }
    });
  }

  function getLastNodes(nodeDataArray, linkDataArray) {
    const endNode = lodashService.find(nodeDataArray, function (node) {
      return node.eventDimension === 8;
    });

    // may be deleted by user
    if (endNode === undefined) {
      return undefined;
    }

    const linksToEndNode = lodashService.filter(linkDataArray, function (link) {
      return link.to === endNode.key;
    });
    const lastNodes = [];
    lodashService.forEach(linksToEndNode, function (link) {
      const node = lodashService.find(nodeDataArray, function (node) {
        return (
          node.key === link.from &&
          (node.category === 'activity' ||
            node.category === 'component' ||
            flowinglyGatewayService.isGateway(node))
        );
      });
      lastNodes.push(node);
    });
    return lastNodes;
  }

  function getStartModelNode(nodeDataArray) {
    return nodeDataArray.find((n) => {
      return n.category === 'event' && n.text === 'Start';
    });
  }

  function getRootModelNode(nodeDataArray, linkDataArray) {
    let links = linkDataArray;
    let startNode = getStartModelNode(nodeDataArray);

    // if there isn't a start node then create a dummy start node
    if (!startNode) {
      // find a key one less than the lowest numbered key
      const newKey =
        nodeDataArray.reduce(
          (accumulator, currentValue) =>
            Math.min(accumulator, currentValue.key),
          Number.MAX_VALUE
        ) - 1;
      startNode = {
        id: newKey.toString(),
        key: newKey
      };
    }

    // add links from the startnode to any node that doesn't have any links pointing to it
    const unconnectedNodes = nodeDataArray.filter(
      (n) => getLinksToNode(n.key, linkDataArray).length === 0
    );
    links = links.concat(
      unconnectedNodes.map((n) => ({
        from: startNode.key,
        to: n.key
      }))
    );

    return { node: startNode, links };
  }

  function getFirstModelNode(nodeDataArray, linkDataArray) {
    const dummyNode = { id: '-1' };
    const startNode = getStartModelNode(nodeDataArray);

    if (startNode) {
      const linkDataFromStartNode = linkDataArray.find((l) => {
        return l.from === startNode.key;
      });

      if (linkDataFromStartNode) {
        const firstNode = nodeDataArray.find((n) => {
          return n.key === linkDataFromStartNode.to;
        });

        if (firstNode) {
          return firstNode;
        } else {
          return dummyNode;
        }
      } else {
        return dummyNode;
      }
    } else {
      return dummyNode;
    }
  }

  function getLinkProcess(nodeDataArray, linkArray, link) {
    const prevNode = getNodeByKey(nodeDataArray, link.from);

    if (prevNode) {
      if (flowinglyGatewayService.isExclusiveGateway(prevNode)) {
        return `P_${link.from}`;
      } else if (
        prevNode.category.toLowerCase() ===
        flowinglyConstants.nodeCategory.DIVERGE_GATEWAY
      ) {
        return `P_${link.from}_${link.to}`;
      } else {
        const links = lodashService.filter(linkArray, function (l) {
          return l.to === prevNode.key && l.linkProcess && l.linkProcess !== '';
        });

        if (links && links.length > 0) {
          return links[0].linkProcess;
        }
      }
    }

    return undefined;
  }

  function findNodesAtEndOfLinks(
    linkArray: IModelNodeLink[],
    nodeDataArray: IModelNode[]
  ) {
    const nodes: IModelNode[] = [];
    linkArray.forEach((link) => {
      const node = lodashService.find(nodeDataArray, function (node) {
        return node.key === link.to && !link.isBacklink;
      });
      if (node != undefined) {
        nodes.push(node);
      }
    });
    return nodes;
  }

  function findNodesAtStartOfLinks(linkArray, nodeDataArray) {
    const nodes = [];
    lodashService.forEach(linkArray, function (link) {
      const node = lodashService.find(nodeDataArray, function (node) {
        return node.key === link.from && !link.isBacklink;
      });
      if (node != undefined) {
        nodes.push(node);
      }
    });
    return nodes;
  }

  function getActorType(nodeTo) {
    let actorType = 'User';

    if (nodeTo.actor != undefined) {
      //If actor is not a GUID (user) then set actor type appropriately (Initiator/InitiatorManager)
      if (
        !nodeTo.actor.match(
          /^[{]?[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[}]?$/
        )
      ) {
        actorType = nodeTo.actor;
      }
    }
    return actorType;
  }

  function getDefaultGatewayCondition(link, gate) {
    let conditionType = 'Action';

    //Set otherwise condition for default gateway
    if (link.to === gate.RouteToKey) {
      conditionType = 'Otherwise';
    }

    return conditionType;
  }

  function getLinkThatConnectsNodes(linkDataArray, nodeTo, nodeFrom) {
    let linkIndex;

    if (nodeFrom != undefined) {
      linkIndex = lodashService.findIndex(linkDataArray, function (lnk) {
        return (
          lnk.to === nodeTo.key && lnk.from === nodeFrom.key && !lnk.isBacklink
        );
      });
    } else {
      linkIndex = lodashService.findIndex(linkDataArray, function (lnk) {
        return lnk.to === nodeTo.key && !lnk.isBacklink;
      });
    }

    const link = linkDataArray[linkIndex];

    return link;
  }

  function getNodeLinkedTo(
    nodeDataArray: IModelNode[],
    link: IModelNodeLink
  ): IModelNode {
    if (!link) {
      return null;
    }

    const node = lodashService.find(nodeDataArray, function (node) {
      return node.key === link.to;
    });

    return node;
  }

  function getNodeLinkedFrom(nodes: IModelNode[], link: IModelNodeLink) {
    if (!link || !nodes) {
      return null;
    }

    const node = nodes.find((node) => node.key === link.from);
    return node;
  }

  function getNodeByKey(nodeDataArray, key) {
    const nodeIndex = lodashService.findIndex(nodeDataArray, function (nde) {
      return nde.key === key;
    });

    return nodeDataArray[nodeIndex];
  }

  function getNodeById(nodeDataArray, id) {
    const node = lodashService.find(nodeDataArray, function (node) {
      return node.id === id;
    });

    return node;
  }

  function getNodeKeyById(nodeDataArray, id) {
    const node = lodashService.find(nodeDataArray, function (node) {
      return node.id === id;
    });

    return node.key;
  }

  function getNodeLinksFrom(linkDataArray: IModelNodeLink[], key: number) {
    const links = linkDataArray.filter((link) => {
      return link.from === key && !link.isBacklink;
    });
    return links;
  }

  function getActivityNodes(nodeDataArray) {
    return lodashService.filter(nodeDataArray, (node) => {
      return node.category === flowinglyConstants.nodeCategory.ACTIVITY;
    });
  }

  function getComponentNodes(nodeDataArray) {
    return lodashService.filter(nodeDataArray, (node) => {
      return node.category === flowinglyConstants.nodeCategory.COMPONENT;
    });
  }

  function getNextNodesForNode(
    nodeKey: number,
    nodeDataArray: IModelNode[],
    linkDataArray: IModelNodeLink[]
  ) {
    const links = getLinksFromNode(nodeKey, linkDataArray);
    const nodes = links.map((l) => getNodeLinkedTo(nodeDataArray, l));
    return nodes;
  }

  function getNextActivityNodesForNodeRecursive(
    nodeKey,
    nodeDataArray,
    linkDataArray,
    nextActivityNodes,
    stopAtMergeGateway
  ) {
    const nextNodes = getNextNodesForNode(
      nodeKey,
      nodeDataArray,
      linkDataArray
    );
    if (!nextActivityNodes) nextActivityNodes = [];

    lodashService.each(nextNodes, (node) => {
      switch (node.category) {
        case flowinglyConstants.nodeCategory.ACTIVITY:
        case flowinglyConstants.nodeCategory.COMPONENT:
          if (nextActivityNodes.find((n) => n.key === node.key)) break;
          nextActivityNodes.push(node);
          break;
        case flowinglyConstants.nodeCategory.DIVERGE_GATEWAY:
        case flowinglyConstants.nodeCategory.CONVERGE_GATEWAY:
          if (!stopAtMergeGateway)
            getNextActivityNodesForNodeRecursive(
              node.key,
              nodeDataArray,
              linkDataArray,
              nextActivityNodes,
              stopAtMergeGateway
            );
          break;
        case flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY:
          getNextActivityNodesForNodeRecursive(
            node.key,
            nodeDataArray,
            linkDataArray,
            nextActivityNodes,
            stopAtMergeGateway
          );
          break;
        case flowinglyConstants.nodeCategory.EVENT:
        default:
          break;
      }
    });

    return nextActivityNodes;
  }

  function getNextComponentNodesForNodeRecursive(
    nodeKey,
    nodeDataArray,
    linkDataArray,
    nextComponentNodes,
    stopAtMergeGateway
  ) {
    const nextNodes = getNextNodesForNode(
      nodeKey,
      nodeDataArray,
      linkDataArray
    );
    if (!nextComponentNodes) nextComponentNodes = [];

    lodashService.each(nextNodes, (node) => {
      switch (node.category) {
        case flowinglyConstants.nodeCategory.COMPONENT:
          if (nextComponentNodes.find((n) => n.key === node.key)) break;
          nextComponentNodes.push(node);
          break;
        case flowinglyConstants.nodeCategory.DIVERGE_GATEWAY:
        case flowinglyConstants.nodeCategory.CONVERGE_GATEWAY:
          if (!stopAtMergeGateway)
            getNextComponentNodesForNodeRecursive(
              node.key,
              nodeDataArray,
              linkDataArray,
              nextComponentNodes,
              stopAtMergeGateway
            );
          break;
        case flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY:
          getNextComponentNodesForNodeRecursive(
            node.key,
            nodeDataArray,
            linkDataArray,
            nextComponentNodes,
            stopAtMergeGateway
          );
          break;
        case flowinglyConstants.nodeCategory.EVENT:
        case flowinglyConstants.nodeCategory.ACTIVITY:
        default:
          break;
      }
    });

    return nextComponentNodes;
  }

  function getPreviousNodesForNode(
    nodeKey: number,
    nodes: IModelNode[],
    links: IModelNodeLink[]
  ) {
    const linksToNode = getLinksToNode(nodeKey, links);
    const previousNodes = linksToNode.map((l) => getNodeLinkedFrom(nodes, l));
    return previousNodes;
  }

  function getAllActivityNodesAfter(
    nodeKey,
    nodeDataArray,
    linkDataArray,
    nextActivityNodes = []
  ) {
    const nextNodes = getNextActivityNodesForNodeRecursive(
      nodeKey,
      nodeDataArray,
      linkDataArray,
      [],
      0
    );

    if (!nextNodes || nextNodes.length === 0) return [];

    lodashService.forEach(nextNodes, (node) => {
      if (
        lodashService.find(nextActivityNodes, (n) => {
          return n.key === node.key;
        })
      ) {
        return;
      }
      nextActivityNodes.push(node);
      getAllActivityNodesAfter(
        node.key,
        nodeDataArray,
        linkDataArray,
        nextActivityNodes
      );
    });

    return nextActivityNodes;
  }

  /**
   *
   * Function is deprecated because it is not flexible, recursion
   * conditions are whacked and gojs has a built in function
   * that does the same thing but is more performant.
   *
   * Consider using .findPreviousUntil
   *
   * @deprecated
   * @param {any} nodeKey
   * @param {any} nodeDataArray
   * @param {any} linkDataArray
   * @param {any} prevActivityNodes
   * @param {any} getAll
   * @param {any} stopAtMergeGateway
   */
  function getPreviousAcitivityNodesForNodeRecursive(
    nodeKey: number,
    nodeDataArray: IModelNode[],
    linkDataArray: IModelNodeLink[],
    prevActivityNodes: IModelNode[],
    getAll,
    stopAtMergeGateway?
  ) {
    console.debug(
      new Error(
        '@deprecated function getPreviousAcitivityNodesForNodeRecursive() used'
      )
    );

    const prevNodes = getPreviousNodesForNode(
      nodeKey,
      nodeDataArray,
      linkDataArray
    );
    if (!prevActivityNodes) prevActivityNodes = [];

    lodashService.each(prevNodes, (node) => {
      switch (node.category) {
        case flowinglyConstants.nodeCategory.ACTIVITY:
          if (prevActivityNodes.find((n) => n.key === node.key)) break;
          prevActivityNodes.push(node);
          if (getAll)
            getPreviousAcitivityNodesForNodeRecursive(
              node.key,
              nodeDataArray,
              linkDataArray,
              prevActivityNodes,
              getAll,
              stopAtMergeGateway
            );
          break;
        case flowinglyConstants.nodeCategory.COMPONENT:
          if (getAll)
            getPreviousAcitivityNodesForNodeRecursive(
              node.key,
              nodeDataArray,
              linkDataArray,
              prevActivityNodes,
              getAll,
              stopAtMergeGateway
            );
          break;
        case flowinglyConstants.nodeCategory.DIVERGE_GATEWAY:
        case flowinglyConstants.nodeCategory.CONVERGE_GATEWAY:
          if (!stopAtMergeGateway)
            getPreviousAcitivityNodesForNodeRecursive(
              node.key,
              nodeDataArray,
              linkDataArray,
              prevActivityNodes,
              getAll,
              stopAtMergeGateway
            );
          break;
        case flowinglyConstants.nodeCategory.EXCLUSIVE_GATEWAY:
          getPreviousAcitivityNodesForNodeRecursive(
            node.key,
            nodeDataArray,
            linkDataArray,
            prevActivityNodes,
            getAll,
            stopAtMergeGateway
          );
          break;
        case flowinglyConstants.nodeCategory.EVENT:
        default:
          break;
      }
    });

    return prevActivityNodes;
  }

  /**
   * This is a more streamlined version of getPreviousAcitivityNodesForNodeRecursive
   * Which allows you to select your predicate and use the gojs iterator native instead
   * of iterating through it ourselves which is highly inefficient
   *
   * Example:
   *  Find the start node
   *      const startNode = findPreviousUntil(fromGoNode, (node) =>{
   *          return isStartNode( node );
   *      })
   *
   * @param {go.Node} fromGoNode
   * @param {function} predicate
   */
  function findPreviousUntil(fromGoNode, predicateFn, diagram) {
    const context = {
      diagram,
      visitTable: {}
    };
    return _findPreviousUntil(
      fromGoNode,
      (currentGoNode) =>
        fromGoNode != currentGoNode && predicateFn(currentGoNode),
      context
    );
  }

  function _findPreviousUntil(currentGoNode, predicateFn, context) {
    const { diagram, visitTable } = context;
    if (predicateFn(currentGoNode)) {
      return currentGoNode;
    } else {
      const links = currentGoNode.findLinksInto();
      while (links.next()) {
        const link = links.value.data;
        const child = diagram.findNodeForKey(link.from);
        const visitorKey = `${link.from}_${link.to}`;
        if (visitTable[visitorKey] == null) {
          visitTable[visitorKey] = 0;
        } else if (++visitTable[visitorKey] > SAFE_RECURSION_MAX) {
          // this means that if you've travelled the relationship more than
          // the alloted time, we are going to assume you are stuck in a
          // recursion loop
          throw new Error(
            'Warning: findPrevious reached an unsafe level of recrusion. Terminating...'
          );
        }
        if (link == null || !link.isBacklink) {
          const prevGoNode = _findPreviousUntil(child, predicateFn, context);
          if (prevGoNode) {
            return prevGoNode;
          }
        }
      }
      return null;
    }
  }

  function getPreviousNodesDataSourceForDropdown(
    nodeKey,
    nodeDataArray,
    linkDataArray,
    prevActivityNodes,
    getAll
  ) {
    let allPreviousModelNodes = getPreviousAcitivityNodesForNodeRecursive(
      nodeKey,
      nodeDataArray,
      linkDataArray,
      prevActivityNodes,
      getAll
    );
    allPreviousModelNodes = allPreviousModelNodes.filter((node) => {
      return (
        flowinglyActivityService.isTaskOrSingleApprovalActivity(node) ||
        node.taskType == flowinglyConstants.taskType.PUBLIC_FORM
      ); // FLOW-4700 allow public forms as a previous nodes ds
    });

    if (allPreviousModelNodes && allPreviousModelNodes.length > 0) {
      const keyValues = allPreviousModelNodes.map((pn) => {
        return { Key: pn.id, Value: pn.text };
      });

      return keyValues;
    }

    return undefined;
  }

  function getLinksFromNode(nodeKey: number, linkDataArray: IModelNodeLink[]) {
    const links = linkDataArray.filter((link) => {
      return link.from === nodeKey && !link.isBacklink;
    });
    return links;
  }

  function getLinksToNode(nodeKey: number, links: IModelNodeLink[]) {
    const linksToNode = links.filter(
      (link) => link.to === nodeKey && !link.isBacklink
    );
    return linksToNode;
  }

  //PLEASE NOTE THAT IF LOGIC FOR SETTING TRIGGER TYPES CHANGES TO UPDATE:
  //
  //  BpmnCommonService.insertLink();
  //
  function getTrigger(nodeDataArray, link) {
    const prevNode = getNodeByKey(nodeDataArray, link.from);
    const trigger = {
      Type: 'Command',
      NameRef: 'ExecuteActivityCommand'
    };

    ///
    /// All nodes need to set to a trigger type of Command apart from the start node which needs to be Auto to
    /// transition to the next step when a flow is started
    ///
    const eventStart = 1;

    //if start node
    if (
      prevNode.category === 'event' &&
      prevNode.eventDimension === eventStart
    ) {
      trigger.Type = 'Auto';
    }

    if (flowinglyGatewayService.isGateway(prevNode)) {
      trigger.Type = flowinglyGatewayService.isExclusiveGateway(prevNode)
        ? 'Auto'
        : 'Command';
      trigger.NameRef = flowinglyGatewayService.isExclusiveGateway(prevNode)
        ? 'GatewayDecisionCommand'
        : 'ExecuteActivityCommand';
    }

    if (link.isBacklink) {
      trigger.Type = 'Auto';
      trigger.NameRef = 'GatewayDecisionCommand';
    }

    return trigger;
  }

  function isNodeGateway(nodeDataArray, key) {
    const node = getNodeByKey(nodeDataArray, key);

    //add  gateway conditions
    if (flowinglyGatewayService.isGateway(node)) {
      return true;
    }

    return false;
  }

  function isMultipleApprovalNode(node) {
    return (
      node &&
      (node.stepType === flowinglyConstants.stepType.PARALLEL_APPROVAL ||
        node.stepType === flowinglyConstants.stepType.SEQUENTIAL_APPROVAL)
    );
  }

  function doesNodeHaveBacklink(node, linkDataArray) {
    return linkDataArray.some((l) => l.isBacklink && l.from === node.key);
  }

  function getNodeBacklinkToNode(node, nodeDataArray, linkDataArray) {
    const backlink = linkDataArray.find(
      (l) => l.isBacklink && l.from === node.key
    );
    if (backlink) {
      return nodeDataArray.find((n) => n.key === backlink.to);
    }
    return undefined;
  }

  function getEligibleCellsInFields(tableFields) {
    let eligibleCells = [];
    if (!tableFields || tableFields.length < 1) {
      return eligibleCells;
    }

    lodashService.forEach(tableFields, (field) => {
      if (field.typeName !== 'Table') {
        return;
      }

      const cells = getEligibleCellsInTable(field);
      eligibleCells = eligibleCells.concat(cells);
    });

    return eligibleCells;
  }
  function isDropdownCellNumeric(cell) {
    if (cell.type === flowinglyConstants.tableCellType.DROPDOWN) {
      if (
        cell.dbDataSource &&
        Array.isArray(cell.dbDataSource.displayValueOptions)
      ) {
        const matchingOption = cell.dbDataSource.displayValueOptions.find(
          (option) => option.name === cell.dbDataSource.displayValue
        );
        if (
          matchingOption?.dataType ===
            flowinglyConstants.customDataTypeName.NUMBER ||
          matchingOption?.dataType ===
            flowinglyConstants.customDataTypeName.CURRENCY
        ) {
          return true;
        }
      } else if (!isNaN(cell.sum) || !isNaN(cell.value)) {
        return true;
      }
    }
    return false;
  }

  function isLookupNumberOrCurrencyCell(cell) {
    if (
      cell.type === flowinglyConstants.tableCellType.LOOKUP &&
      cell.lookupConfig
    ) {
      return (
        cell.lookupConfig.displayValueType ===
          flowinglyConstants.customDataTypeName.CURRENCY ||
        cell.lookupConfig.displayValueType ===
          flowinglyConstants.customDataTypeName.NUMBER
      );
    }
    return false;
  }
  function isFormulaCellNumeric(cell, cells) {
    if (cell.type !== flowinglyConstants.tableCellType.FORMULA) {
      return false;
    }

    let isNumber = false;
    let isCurrency = false;

    for (const element of cell.formulaConfig.formulaOperands) {
      const columnId = parseInt(element.replace('column', ''), 10);
      const column = cells.find((o) => o.id === columnId);

      if (!column) continue;

      if (
        column.dbDataSource &&
        Array.isArray(column.dbDataSource.displayValueOptions)
      ) {
        const matchingOption = column.dbDataSource.displayValueOptions.find(
          (option) => option.name === column.dbDataSource.displayValue
        );
        if (
          matchingOption?.dataType ===
          flowinglyConstants.customDataTypeName.CURRENCY
        ) {
          isCurrency = true;
          cell.columnType = flowinglyConstants.customDataTypeName.CURRENCY;
        } else if (
          matchingOption?.dataType ===
          flowinglyConstants.customDataTypeName.NUMBER
        ) {
          isNumber = true;
          cell.columnType = flowinglyConstants.customDataTypeName.NUMBER;
        }
      } else if (column.type === flowinglyConstants.tableCellType.NUMBER) {
        isNumber = true;
        cell.columnType = flowinglyConstants.formFieldType.NUMBER;
      } else if (column.type === flowinglyConstants.tableCellType.CURRENCY) {
        isCurrency = true;
        cell.columnType = flowinglyConstants.formFieldType.CURRENCY;
      }

      if (isNumber || isCurrency) {
        return true;
      }
    }

    return false;
  }

  function getEligibleCellsInTable(field) {
    const eligibleCells = [];
    if (!field.tableSchema) {
      return eligibleCells;
    }

    let cells = {};
    if (Array.isArray(field.tableSchema)) {
      cells = field.tableSchema;
    } else {
      try {
        cells = JSON.parse(field.tableSchema);
      } catch (error) {
        console.error(field.tableSchema);
        console.error(error);
        return eligibleCells;
      }
    }

    lodashService.forEach(cells, (col) => {
      if (
        col.type === flowinglyConstants.tableCellType.CURRENCY ||
        col.type === flowinglyConstants.tableCellType.NUMBER ||
        isDropdownCellNumeric(col) ||
        isLookupNumberOrCurrencyCell(col) ||
        isFormulaCellNumeric(col, cells)
      ) {
        lodashService.forEach(
          flowinglyConstants.tableNumberCalculationTypes,
          (type) => {
            eligibleCells.push({
              id: col.id,
              displayName:
                field.displayName + ' - ' + type + ' of ' + col.header,
              name: field.name + '__' + col.id + '_' + type.toLowerCase(),
              typeName: getTypeName(col)
            });
          }
        );
      }
    });
    return eligibleCells;
  }

  function getTypeName(cell) {
    if (
      cell.type === flowinglyConstants.tableCellType.NUMBER ||
      cell.type === flowinglyConstants.tableCellType.CURRENCY
    ) {
      return cell.type;
    } else if (cell.type === flowinglyConstants.tableCellType.DROPDOWN) {
      if (
        cell.dbDataSource &&
        Array.isArray(cell.dbDataSource.displayValueOptions)
      ) {
        const matchingOption = cell.dbDataSource.displayValueOptions.find(
          (option) => option.name === cell.dbDataSource.displayValue
        );
        if (
          matchingOption?.dataType === flowinglyConstants.formFieldType.CURRENCY
        ) {
          return flowinglyConstants.tableCellType.CURRENCY;
        } else if (
          matchingOption?.dataType === flowinglyConstants.formFieldType.NUMBER
        ) {
          return flowinglyConstants.tableCellType.NUMBER;
        }
      }
    } else if (cell.type === flowinglyConstants.tableCellType.LOOKUP) {
      if (
        cell.lookupConfig.displayValueType ===
        flowinglyConstants.formFieldType.CURRENCY
      ) {
        return flowinglyConstants.tableCellType.CURRENCY;
      } else if (
        cell.lookupConfig.displayValueType ===
        flowinglyConstants.formFieldType.NUMBER
      ) {
        return flowinglyConstants.tableCellType.NUMBER;
      }
    } else if (cell.type === flowinglyConstants.tableCellType.FORMULA) {
      if (cell.columnType === flowinglyConstants.formFieldType.NUMBER)
        return flowinglyConstants.tableCellType.NUMBER;
      if (cell.columnType === flowinglyConstants.formFieldType.CURRENCY)
        return flowinglyConstants.tableCellType.CURRENCY;
    }
  }
  function transformTableCellToField(tableCells) {
    lodashService.forEach(tableCells, (cell) => {
      if (cell.typeName == flowinglyConstants.tableCellType.CURRENCY) {
        cell.typeName = flowinglyConstants.formFieldTypePascal.CURRENCY;
        cell.type = flowinglyConstants.formFieldType.CURRENCY;
      } else if (cell.typeName == flowinglyConstants.tableCellType.NUMBER) {
        cell.typeName = flowinglyConstants.formFieldTypePascal.NUMBER;
        cell.type = flowinglyConstants.formFieldType.NUMBER;
      }
    });
    return tableCells;
  }

  /// PRIVATE METHODS //////////////////////////////////
  function logLinks(links) {
    devLoggingService.log('Found the following links connected to this node: ');
    lodashService.forEach(links, function (link) {
      devLoggingService.log(' ' + link.from + ' to ' + link.to);
    });
  }

  function logNodes(nodes) {
    devLoggingService.log('Found the following nodes connected to the links: ');
    lodashService.forEach(nodes, function (node) {
      const displayName =
        node.Card &&
        node.Card.formElements != undefined &&
        node.Card.formElements.length > 0
          ? node.Card.formElements[0].displayName
          : '';
      devLoggingService.log(' ' + node.text + ' : ' + displayName);
    });
  }
}

export type FlowinglyModelUtilityServiceType = ReturnType<
  typeof flowinglyModelUtilityService
>;
